<template>
  <div>
    
    <EmptyState v-if="paciente && fidelizacoes && fidelizacoes.length == 0">
      <h3>
        Nenhuma fidelidade encontrada!
      </h3>
    </EmptyState>
    <div class="paciente-fidelizacoes ">
      <div class="fidelizacoes-container">
        <paciente-fidelidade-card
          dark
          v-for="fidel in fidelizacoes"
          :fidelizacao="fidel"
          :key="fidelizacoes.indexOf(fidel)"
          @click.native="exibeFidelidade(fidel)"
        />
      </div>

      <b-modal size="lg" id="fidelidade-exibicao" :title="`Fidelidade Nº ${fidelidadeExib.codigo}`" hide-footer>
        <paciente-fidelidades-exibicao :fidelidade="fidelidadeExib" />
      </b-modal>
    </div>
  </div>
</template>

<script>
//import PacienteItemCard from "../PacienteItemCard.vue";
import EmptyState from "../../../common/EmptyState.vue";
import PacienteFidelidadesExibicao from "./PacienteFidelidadesExibicao.vue";
import PacienteFidelidadeCard from "./PacienteFidelidadeCard.vue";
export default {
  name:"PacienteFidelidade",
  components: {
    EmptyState,
    PacienteFidelidadesExibicao,
    PacienteFidelidadeCard,
  },
  props: {
    paciente: Object,
  },
  mounted() {
    this.fidelizacoes = JSON.parse(
      JSON.stringify(
        this.paciente && this.paciente.fidelizacoes
          ? this.paciente.fidelizacoes
          : []
      )
    );
  },
  data() {
    return {
      loading: false,
      loadingSecundario: false,
      opened: false,
      fidelizacoes: [],
      fidelidadeExib: {},
    };
  },
  watch: {},
  computed: {},
  methods: {
    exibeFidelidade(fidelidade) {
      this.fidelidadeExib = fidelidade;
      this.$bvModal.show("fidelidade-exibicao");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/default.scss";

@import "@/assets/scss/paciente.scss";
@import "@/assets/scss/venda.scss";
</style>
