<template>
  <div>
    <div class="shadow-sm p-2">
      <div class="fidelidade-exibicao">
        <div class="fidelizacoes-container">
          <PacienteFidelidadeCard :fidelizacao="fidelidade" />
        </div>
      </div>

      <b-tabs class="mt-3 border rounded shadow p-2">
        <b-tab>
          <template #title>
            <div>
              <h3><b-icon icon="credit-card"></b-icon> Dados do Cartão</h3>
            </div>
          </template>
          <div class="row  rounded px-3 pt-3 m-1">
            <div class="col border  py-1 rounded mx-1 my-1 text-center">
              <div>
                <b>Data de Aquisição</b>
                {{ fidelidade.dt_aquisicao | moment("DD/MM/YYYY") }}
              </div>
            </div>

            <div class="col border  py-1 rounded mx-1 my-1 text-center">
              <div>
                <b>Data de Vencimento</b>
                {{ fidelidade.dt_vencimento | moment("DD/MM/YYYY") }}
              </div>
            </div>
          </div>
          <div class="row flex-column  rounded px-3 m-1">
            <div class="col border  py-1 rounded mx-1 my-1 text-center">
              <div>
                <b>Código:</b>
                {{ fidelidade.codigo }}
              </div>
            </div>

            <!-- <div
          class="col border  py-1 rounded mx-1 my-1 text-center"
        >
          <div>
            <b>Data de Vencimento</b>
            {{ fidelidade.dt_vencimento | moment("DD/MM/YYYY") }}
          </div>
        </div> -->
          </div>
          <div class="p-3 mb-1">
            <h4 class="text-center">Descontos</h4>
            <div class="row rounded  px-3">
              <div
                v-for="desconto of descontos"
                :key="descontos.indexOf(desconto)"
                class="col border  py-1 rounded mx-1 my-2 text-center"
              >
                <div class="d-flex flex-column ">
                  <div>
                    <b>Tipo:</b>
                    {{ desconto.tipo_venda }}
                  </div>
                  <div>
                    <b>Desconto:</b>
                    <div class="d-inline badge-danger pr-1 ml-1">
                      {{ desconto.desconto_tipo == "P" ? "%" : "R$ "
                      }}{{ desconto.desconto_valor }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-3 m-1">
            <h4 class="text-center">Dependentes</h4>
            <div class="row justify-content-start rounded px-3">
              <div class="text-center" v-if="dependentes.length == 0">
                Nenhum dependente.
              </div>
              <div
                v-for="dependente of dependentes"
                :key="dependente.entidade_id"
                @click="openDependenteFicha(dependente.entidade_id)"
                class="col d-flex  align-items-center justify-content-center border  py-1 rounded mx-1 my-1 text-center default-hover"
              >
                <div class="d-flex flex-column ">
                  <div>
                    <b>Nome:</b>
                    {{ dependente.Nome }}
                  </div>
                  <div>
                    <b>CPF:</b>
                    {{ dependente.CPF }}
                  </div>
                  <div>
                    <b>Parentesco:</b>
                    {{ dependente.Parentesco }}
                  </div>
                </div>
              </div>

              <!-- <div
          class="col border  py-1 rounded mx-1 my-1 text-center"
        >
          <div>
            <b>Data de Vencimento</b>
            {{ fidelidade.dt_vencimento | moment("DD/MM/YYYY") }}
          </div>
        </div> -->
            </div>

            <div class="row justify-content-end mt-5">
              <b-btn @click="resnc" class="mr-3">
                <i class="fa fa-recycle" /> Sincronizar
              </b-btn>
              <b-btn @click="openContrato" variant="primary">
                <i class="fas fa-handshake"></i> Contrato</b-btn
              >
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div>
              <h3>
                <b-icon v-if="!loadingHistorico" icon="clock-history"></b-icon>
                <b-spinner v-if="loadingHistorico"></b-spinner> Histórico de
                Renovação
              </h3>
            </div>
          </template>

          <div class="row p-3">
            <div
              v-if="loadingHistorico"
              class="col-12 d-flex justify-content-center"
            >
              <b-spinner class="mr-2" type="grow"></b-spinner>
              <h5>
                Carregando...
              </h5>
            </div>

            <div
              class="col text-center text-muted"
              v-if="!loadingHistorico && historicoRenovacoes.length <= 0"
            >
              <h3>Nenhum histórico de renovação...</h3>
              <EmptyState></EmptyState>
            </div>
            <div
              class="col"
              v-if="!loadingHistorico && historicoRenovacoes.length > 0"
            >
              {{ historicoRenovacoes }}
            </div>
          </div>
        </b-tab>
      </b-tabs>

      <b-modal
        id="modal-contrato-print"
        no-enforce-focus
        no-fade
        hide-backdrop
        hide-footer
        hide-header-close
        hide-header
        v-if="fidelidade"
      >
        <PrintComponent
          :filename="`Contrato ${fidelidade.fidelizacao.nome} `"
          v-if="fidelidade"
          :printNow="printNow"
          @done="printNow = false"
        >
          <div class="container">
            <div class="row">
              <div class="col">
                <div v-html="contrato"></div>
              </div>
            </div>
          </div>
        </PrintComponent>
      </b-modal>

      <b-modal id="modal-contrato" hide-footer size="lg">
        <template #modal-title>
          <i class="fas fa-handshake"></i> Contrato -
          {{ fidelidade.fidelizacao.nome }}
        </template>

        <div class="container">
          <div class="row">
            <div class="col">
              <div v-html="contrato"></div>
            </div>
          </div>
        </div>

        <div class="row">
          <div
            class="col d-flex  justify-content-end mt-3 pt-3 py-2 border-top"
          >
            <b-btn @click="print"><i class="fas fa-print"></i> Imprimir</b-btn>
          </div>
        </div>
      </b-modal>

      <!-- <h4>Contrato</h4>
      <div class="row">
        <div class="col">
          <div v-html="JSON.parse(fidelidade.contrato).content"></div>
        </div>
      </div> -->
      <b-modal
        id="dependente-ficha"
        size="lg"
        modal-class="modalMaiorFull"
        body-class="modalNoPadding"
        hide-footer
        hide-header
        @close="closeDependenteFicha"
      >
        <!-- <b-spinner  v-if="loadingFicha"></b-spinner> -->
        <DependenteFicha
          @close="closeDependenteFicha"
          :paciente_id="openedDependenteId"
          @loading="setLoadingFicha"
        ></DependenteFicha>
      </b-modal>
    </div>
  </div>
</template>

<script>
import PacienteFidelidadeCard from "./PacienteFidelidadeCard.vue";
import PrintComponent from "../../../Print/PrintComponent.vue";
import FidelizacoesLib from "../../../../libs/FidelizacoesLib";
import EmptyState from "../../../common/EmptyState.vue";
export default {
  components: {
    PacienteFidelidadeCard,
    PrintComponent,
    DependenteFicha: () => import("../PacienteFicha.vue"),
    EmptyState,
  },
  data() {
    return {
      historicoRenovacoes: [],
      printNow: false,
      openedDependenteId: null,
      loadingFicha: false,
      loadingHistorico: false,
    };
  },
  props: {
    fidelidade: Object,
  },
  methods: {
    async getRenovacoes() {
      this.loadingHistorico = true;
      this.historicoRenovacoes = await FidelizacoesLib.getHistoricoRenovacoes(
        this.fidelidade.id
      );

      this.loadingHistorico = false;
    },
    openContrato() {
      this.$bvModal.show("modal-contrato");
    },
    openDependenteFicha(entidadeId) {
      if (!entidadeId) return;

      this.openedDependenteId = entidadeId;
      this.$bvModal.show("dependente-ficha");
    },
    closeDependenteFicha() {
      this.openedDependenteId = null;
      this.$bvModal.hide("dependente-ficha");
    },

    print() {
      this.printNow = true;
      this.$bvModal.show("modal-contrato-print");
    },
    setLoadingFicha(loading) {
      this.loadingFicha = loading;
    },
    resnc() {
      if (!this.fidelidade.codigo) {
        this.$swal(
          "Atenção!",
          "O código desta fidelização está inválido",
          "error"
        );
        return;
      }
      FidelizacoesLib.resync(this.fidelidade.codigo);
      this.$swal(
        "Solicitado!",
        "Re-sincornização solicitado, aguarde de 30 a 40 segundos para confirmar",
        "success"
      );
    },
  },
  created() {
    this.getRenovacoes();
  },
  computed: {
    contrato() {
      return JSON.parse(this.fidelidade?.contrato)?.content;
    },
    dependentes() {
      return this.fidelidade.dependentes.filter((d) => d.Nome);
    },
    descontos() {
      return this.fidelidade.fidelizacao.desconto;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/venda.scss";
</style>
