<template>
  <div class="fidelizacao-item" :class="[{vencido: fidelizacao.vencido}, {cancelado: fidelizacao.cancelado}]">
    
    <div v-if="fidelizacao.cancelado" class="isCancelado">
      <i class="fas fa-ban"></i> Esse cartão foi cancelado
    </div>
    <img
      :src="
        dark
          ? require('@/assets/img/icones/menu/loyalty_dark_40px.png')
          : require('@/assets/img/icones/menu/loyalty_40px.png')
      "
    />

    {{ fidelizacao.fidelizacao.nome
    }}<b>{{ fidelizacao.dependente ? " - Dependente" : "" }}</b>

    <div class="fidelizacao-detalhes">
      <p class="fidelizacao-detalhes-item">
        <b>Titular:</b> {{ fidelizacao.titular }}
      </p>
    </div>

    <div class="fidelizacao-codigo"><b>Cód:</b> {{ fidelizacao.codigo }}</div>
    <div class="fidelizacao-validade">
      Val.: {{ fidelizacao.dt_vencimento | moment("DD/MM/YYYY") }}
    </div>
  </div>
</template>

<script>
export default {
  created(){

  },
  props: {
    fidelizacao: Object,
    dark: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/venda.scss";
</style>
